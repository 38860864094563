import React from "react"
import Layout from "../components/layout"
import style from "./page.module.css"
import Img from "gatsby-image"
// import { Link } from "gatsby"


const ToolsPage = ({data}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
      <Layout>
    <div className={style.content} >
     <div dangerouslySetInnerHTML={{ __html: html }}/> 
     <Img className={style.img} fluid={data.file.childImageSharp.fluid} alt="logo" />
     <p><a href="https://www.tricentis.com/" target="_blank" rel="noreferrer">TOSCA Testsuite</a></p>
     {frontmatter.title}
    </div>
    </Layout>
  )
}
export const query = graphql`
query  {
  markdownRemark(fileAbsolutePath: {regex: "/Tools.md/"}) {
    html
    frontmatter {
      title
    }
  }
  file(name: { eq: "prueba_anatomy_of_testing" }, extension: { eq: "png" }) {
    childImageSharp {
      fluid(maxWidth: 1000, pngQuality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
export default ToolsPage

